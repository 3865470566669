<template>
  <div>
    <v-row no-gutters justify="center">
      <v-card v-if="orderStatus === paymentStatuses.COMPLETED" width="85%" class="my-10 pa-5">
        <v-card-title class="mb-2">
          <v-icon size="60" color="black" class="mr-6">
            far fa-thumbs-up
          </v-icon>
          <h2 class="text-h4">Bedankt voor uw bestelling bij FIS Automotive</h2>
        </v-card-title>
        <v-card-text class="black--text">
          <p class="text-subtitle-1">
            We gaan ons best doen deze bestelling zo snel mogelijk te verzorgen.<br>
            Een bevestiging is verstuurd naar {{ yourEmailAddress }}.<br>
            Voor speciale wensen, kunt u altijd een afspraak maken met onze ontwerpers.
          </p>
        </v-card-text>
      </v-card>
      <v-card v-else-if="orderStatus === paymentStatuses.FAILED" width="85%" class="my-10 pa-5">
        <v-card-title class="mb-2">
          <v-icon size="60" color="black" class="mr-6">
            far fa-thumbs-up
          </v-icon>
          <h2 class="text-h4">Uw betaling is helaas niet gelukt voor uw bestelling bij FIS Automotive</h2>
        </v-card-title>
        <v-card-text class="black--text">
          <p class="text-subtitle-1">
            Neem contact met ons op om de order en / of betaling te controleren.
          </p>
        </v-card-text>
      </v-card>
      <v-card v-else width="85%" class="my-10 pa-5">
        <v-card-title class="mb-2">
          <v-icon size="60" color="black" class="mr-6">
            far fa-thumbs-up
          </v-icon>
          <h2 class="text-h4">Bedankt voor uw bestelling bij FIS Automotive</h2>
        </v-card-title>
        <v-card-text class="black--text">
          <p class="text-subtitle-1">
            We zijn nog in afwachting van de betaling.<br>
            Zodra dit is gelukt gaan ons best doen deze bestelling zo snel mogelijk te verzorgen.<br>
            Een bevestiging is verstuurd naar {{ yourEmailAddress }}.<br>
            Voor speciale wensen, kunt u altijd een afspraak maken met onze ontwerpers.
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getStatus } from '@/api/order';
import { paymentStatuses } from '@/application/enums/paymentStatus.js';

export default {
  name: 'FinishedOrder',
  data: () => ({
    loading: true,
    orderStatus: paymentStatuses.AWAITING_PAYMENT,
  }),
  created() {
    this.$hero.$emit('setCanSearch', false);
    this.$hero.$emit('setTitle', 'bestelling geplaatst');
    this.$hero.$emit('setBreadcrumbs', [
      {
        text: 'home',
        exact: true,
        to: { name: 'home' },
      },
      {
        text: 'bestelling geplaatst',
        to: { name: 'finished-order' },
      },
    ]);

    this.handleOrderStatus();
  },
  computed: {
    paymentStatuses: () => paymentStatuses,
    yourEmailAddress() {
      const sessionEmail = window.sessionStorage.getItem('email');
      window.sessionStorage.removeItem('email');
      return sessionEmail || 'uw email adres';
    },
  },
  methods: {
    ...mapActions({
      clearOrder: 'order/clearOrder',
    }),
    async handleOrderStatus() {
      this.loading = true;
      const { orderId } = this.$route.query;

      if (orderId) {
        const { data: { data } } = await getStatus(orderId);
        this.orderStatus = data.paymentStatusId;

        if (this.orderStatus !== paymentStatuses.FAILED) {
          if (this.orderStatus === paymentStatuses.COMPLETED) {
            this.handleSuccessfulOrder();
          }
          this.loading = false;
          return;
        }
      }

      this.redirectToMakeOrder();
    },
    handleSuccessfulOrder() {
      this.clearOrder();
    },
    redirectToMakeOrder() {
      this.$router.push({
        name: 'make-order',
        params: {
          step: 3,
        },
      });
    },
  },
};
</script>

<style scoped>
.theme--light.v-timeline:before, .theme--dark.v-timeline:before {
  background: none;
}
</style>
