export const paymentStatuses = {
  AWAITING_PAYMENT: 1,
  COMPLETED: 2,
  FAILED: 3,
}

export const paymentStatusNames = {
  [paymentStatuses.AWAITING_PAYMENT]: 'Afwachtend',
  [paymentStatuses.COMPLETED]: 'Succesvol',
  [paymentStatuses.FAILED]: 'Mislukt',
}
